import BaseBean from "@/utils/BaseBean";

export interface IXsListDataObj {
    utilInst:XsListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class XsListUtil extends BaseBean{
    public dataObj:IXsListDataObj

    constructor(proxy:any,dataObj:IXsListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import XsListUtil,{IXsListDataObj} from './xsListUtil';
export default defineComponent ({
    name: 'XsList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXsListDataObj=reactive<IXsListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/xs/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XsListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        return{
            ...toRefs(dataObj)
        }
    }
});